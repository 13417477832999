function toggleCollapse( e ) {
	let data = e.currentTarget.dataset.collapseToggle;
	let target = document.getElementById( data );

	if( target ) {

		if( target.classList.contains( 'hidden' ) ) {
			target.classList.remove( 'hidden' );
		} else {
			target.classList.add( 'hidden' );
		}

	}
}

let collapseToggles = document.querySelectorAll( '[data-collapse-toggle]' );

collapseToggles.forEach( toggle => {
	toggle.addEventListener( 'click', toggleCollapse );
});
